import React from 'react';
import PropTypes from 'prop-types';
import { TranslationWithHtmlTags } from '../../../common/components/translation-with-html-tags/translation-with-html-tags';

export const FollowNotification = ({ isFollowing }) => {
  return (
    <TranslationWithHtmlTags
      tKey={isFollowing ? 'category-actions.unfollow' : 'category-actions.follow'}
    />
  );
};

FollowNotification.propTypes = {
  className: PropTypes.string,
  isFollowing: PropTypes.bool,
  isBell: PropTypes.bool,
};

export default FollowNotification;
