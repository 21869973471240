import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import withPermissions from '../../hoc/with-permissions';
import withAuth from '../../hoc/with-auth';
import { MODAL_TYPE_UNFOLLOW_CATEGORY } from '../modals/unfollow-category-modal/unfollow-category-modal-type';
import { isFollowButtonVisible } from '../../services/is-follow-button-visible';
import { isSubscribedToAllCategories } from '../../../common/selectors/categories-selectors';
import { MODAL_TYPE_UNFOLLOW_ALL_CATEGORIES } from '../modals/unfollow-all-categories-modal/unfollow-all-categories-modal-type';

export class FollowCategoryHeadless extends React.Component {
  handleSubscribe = () => {
    const {
      category: { _id },
      subscribeToCategory,
      buttonClicked,
    } = this.props;
    buttonClicked({ action: 'follow_category', flag: 1 });

    subscribeToCategory(_id);
  };

  handleUnsubscribe = () => {
    const {
      category: { _id },
      buttonClicked,
      openModal,
    } = this.props;
    buttonClicked({ action: 'follow_category', flag: 0 });
    openModal(MODAL_TYPE_UNFOLLOW_CATEGORY, { categoryId: _id });
  };

  handleSubscribeAll = () => {
    this.props.subscribeToAllCategories();
  };

  handleUnsubscribeAll = () => {
    this.props.openModal(MODAL_TYPE_UNFOLLOW_ALL_CATEGORIES);
  };

  getAction = (category) => {
    const { isFollowingAllCategories, shouldSubscribeToAll } = this.props;
    if (shouldSubscribeToAll) {
      return isFollowingAllCategories ? this.handleUnsubscribeAll : this.handleSubscribeAll;
    } else {
      return category.isSubscribed ? this.handleUnsubscribe : this.handleSubscribe;
    }
  };

  render() {
    const {
      canRender,
      category,
      forPublicUser,
      children,
      shouldSubscribeToAll,
      isFollowingAllCategories,
    } = this.props;
    return canRender(
      [
        ['subscribe', 'category', category],
        ['list', 'category', category],
      ],
      ([canSubscribe]) => {
        if (!isFollowButtonVisible(category, canSubscribe)) {
          // All users can see follow button for all categories, except private ones
          return null;
        }

        const action = this.getAction(category);
        return children({
          onClick: canSubscribe ? action : forPublicUser(action),
          isSubscribed: shouldSubscribeToAll ? isFollowingAllCategories : category.isSubscribed,
          canSubscribe,
        });
      },
    );
  }
}

FollowCategoryHeadless.propTypes = {
  children: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  canRender: PropTypes.func.isRequired,
  subscribeToCategory: PropTypes.func.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  forPublicUser: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  shouldSubscribeToAll: PropTypes.bool,
  isFollowingAllCategories: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  subscribeToCategory: actions.subscribeToCategory,
  openModal: actions.openModal,
  buttonClicked: actions.buttonClicked,
  subscribeToAllCategories: actions.subscribeToAllCategories,
  unsubscribeFromAllCategories: actions.unsubscribeFromAllCategories,
  isFollowingAllCategories: isSubscribedToAllCategories(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withPermissions,
  withAuth,
)(FollowCategoryHeadless);
