import React from 'react';
import { flowRight } from 'lodash';
import { withCategoryActions } from './with-category-actions';
import DropdownButton from '../../components/dropdown-button';
import { mapCategoryActionsToItems } from './category-actions-utils';
import withTranslate from '../../../common/components/with-translate/with-translate';
import ActionButton from '../../components/action-button';
import ensureAuth from '../../hoc/ensure-auth';
import styles from './category-list-actions.scss';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';

const ActionButtonAuth = ensureAuth(ActionButton);

const CategoryListActions = ({ categoryActions, t, actionsOpened, actionsClosed, theme }) => {
  const actionsLength = categoryActions.length;

  if (actionsLength === 0) {
    return null;
  }

  const isDropdown = actionsLength > 1;

  if (isDropdown) {
    return (
      <DropdownButton
        dataHook="category-actions-dropdown-select"
        label={t('more-button.more-actions')}
        secondary
        onDropdownShow={() => actionsOpened({ type: null })}
        onDropdownHide={() => actionsClosed({ type: null })}
      >
        <div className={styles.dropdown}>{mapCategoryActionsToItems(categoryActions, t, theme)}</div>
      </DropdownButton>
    );
  }

  const mainAction = categoryActions[0];

  if (mainAction.component) {
    return mainAction.component;
  }

  const ActionComponent = mainAction.isAuthRequired ? ActionButtonAuth : ActionButton;

  return (
    <ActionComponent
      className={classNames({
        [styles.button]: true,
        'button-color': true,
        'button-border-color': true,
      })}
      actionTextClassName={styles.buttonActionText}
      dataHook={mainAction.dataHook}
      isLink={!!mainAction.to}
      to={mainAction.to}
      queryParams={mainAction.queryParams}
      onClick={mainAction.onClick}
    >
      {mainAction.icon}
      {t(mainAction.label)}
    </ActionComponent>
  );
};

const mapRuntimeToProps = (_state, _ownProps, actions) => {
  return {
    actionsOpened: actions.actionsOpened,
    actionsClosed: actions.actionsClosed,
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withCategoryActions,
)(CategoryListActions);
