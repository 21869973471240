import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { LAYOUT_TYPE_PINBOARD, LAYOUT_TYPE_FEED } from '@wix/communities-forum-client-commons';

import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import withDeviceType from '../../hoc/with-device-type';
import withPermissions from '../../hoc/with-permissions';
import { getLayoutType, isInfiniteScrollLayoutType } from '../../selectors/layout-selectors';
import {
  getAllCategories,
  getUniquePostTypesInAllCategories,
} from '../../../common/selectors/categories-selectors';
import { getPostPageSize } from '../../constants/pagination';
import { getIsCategorySelectEnabled } from '../../selectors/app-settings-selectors';
import { getForumCreatePostCta } from '../../selectors/forum-data-selectors';
import { mapCommentingToPostType } from '../../services/map-commenting-to-post-type';
import styles from './post-list.scss';
import loadable from '@wix/yoshi-flow-editor/loadable';
import LoaderBox from '../loader-box';
import { withCategoryActions } from '../../containers/category-actions/with-category-actions';

const PostListMobile = loadable(() =>
  import(
    /* webpackChunkName: "post-list-mobile" */
    '../post-list-mobile'
  ),
);
const PostListMobileClassic = loadable(() =>
  import(
    /* webpackChunkName: "post-list-mobile-classic" */
    '../post-list-mobile-classic'
  ),
);
const PostListClassic = loadable(() =>
  import(
    /* webpackChunkName: "post-list-classic" */
    './post-list-classic'
  ),
);
const PostListMasonryResponsive = loadable(() =>
  import(
    /* webpackChunkName: "post-list-masonry-responsive" */
    './post-list-masonry-responsive'
  ),
);
const PostListFeed = loadable(() =>
  import(
    /* webpackChunkName: "post-list-feed" */
    './post-list-feed.component'
  ),
);

class PostList extends Component {
  constructor(props) {
    super(props);
    const { isMobile, isInfiniteScrollLayout } = this.props;
    this.state = {
      isInfiniteList: !isMobile && isInfiniteScrollLayout,
    };
  }

  componentDidUpdate() {
    const { isMobile, isInfiniteScrollLayout } = this.props;

    const isInfiniteList = !isMobile && isInfiniteScrollLayout;
    if (this.state.isInfiniteList !== isInfiniteList) {
      this.setState({
        isInfiniteList,
      });
    }
  }

  getListComponent = () => {
    const { isMobile, layout } = this.props;
    if (isMobile) {
      switch (layout) {
        case LAYOUT_TYPE_PINBOARD:
          return PostListMobile;
        case LAYOUT_TYPE_FEED:
          return PostListMobile;
        default:
          return PostListMobileClassic;
      }
    }

    switch (layout) {
      case LAYOUT_TYPE_PINBOARD:
        return PostListMasonryResponsive;
      case LAYOUT_TYPE_FEED:
        return PostListFeed;
      default:
        return PostListClassic;
    }
  };

  getListComponentProps = () => {
    const {
      isLoading,
      showLoaderInLoadMore,
      currentPagePosts,
      allPosts,
      page,
      entityCount,
      isLoaded,
      loadMore,
      changePage: handlePageChange,
      selectedCategorySlug,
      isCategorySelectEnabled,
      showCategoryLink,
      postsPerPage,
      ...props
    } = this.props;
    let postTypes =
      props.uniquePostTypesInAllCategories.length === 1
        ? props.uniquePostTypesInAllCategories
        : props.category.postTypes;

    if (props.category.commentingType) {
      postTypes = mapCommentingToPostType(props.category.commentingType);
    }

    const commonProps = {
      entityCount,
      showFollowCategoryAction: true,
      showFilters: true,
      isLoading,
      showCategoryLink,
      postTypes,
      postsPerPage,
    };

    if (this.state.isInfiniteList) {
      const postsToShow = allPosts.slice(0, page * postsPerPage);
      return {
        posts: postsToShow,
        showLoaderInLoadMore,
        loadMore,
        ...commonProps,
        ...props,
      };
    }

    return {
      page,
      onPageChange: handlePageChange,
      isLoaded,
      posts: currentPagePosts,
      ...commonProps,
      ...props,
    };
  };

  render() {
    const containerClassName = classNames(styles.container, 'post-list');
    const Component = this.getListComponent();
    const componentProps = this.getListComponentProps();

    return (
      <div className={containerClassName}>
        <Component {...componentProps} fallback={<LoaderBox />} />
      </div>
    );
  }
}

PostList.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  changePage: PropTypes.func,
  currentPagePosts: PropTypes.array,
  category: PropTypes.object,
  allPosts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  isMobile: PropTypes.bool,
  layout: PropTypes.number,
  entityCount: PropTypes.number,
  page: PropTypes.number,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  selectedCategorySlug: PropTypes.string,
  isCategorySelectEnabled: PropTypes.bool,
  showLoaderInLoadMore: PropTypes.bool,
  hasActiveFilter: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  emptyStateFragment: PropTypes.node,
  isInfiniteScrollLayout: PropTypes.bool,
  postsPerPage: PropTypes.number,
};

PostList.defaultProps = {
  layout: LAYOUT_TYPE_PINBOARD,
};

const mapRuntimeToProps = (state, { category, can }, actions, host) => {
  let customCtaLabel;
  let showCreatePostAction;
  const allCategories = getAllCategories(state);
  const canCreatePostInCategory = (c) =>
    !!c &&
    ((c.isWriteProtected && can('create-post', 'category', c)) ||
      (!c.isWriteProtected && can('read', 'category', c)));

  // Post list without category._id shows all posts from all categories
  if (!category._id) {
    // Check if user can post in any of the categories
    showCreatePostAction = allCategories.some(canCreatePostInCategory);
    customCtaLabel = getForumCreatePostCta(state);
  } else {
    showCreatePostAction = category && canCreatePostInCategory(category);
    customCtaLabel = category.createPostCtaLabel;
  }

  return {
    showCategoryLink: !category._id && allCategories.length > 1,
    showCreatePostAction,
    layout: getLayoutType(state, host.style),
    onLikeClick: actions.incrementPostLikeCount,
    isCategorySelectEnabled: getIsCategorySelectEnabled(state, host.style),
    isInfiniteScrollLayout: isInfiniteScrollLayoutType(state, host.style),
    uniquePostTypesInAllCategories: getUniquePostTypesInAllCategories(state),
    customCtaLabel,
    postsPerPage: getPostPageSize(state, host.style),
  };
};

export default flowRight(
  withCategoryActions,
  withDeviceType,
  withPermissions,
  connect(mapRuntimeToProps), // Should stay last
)(PostList);
