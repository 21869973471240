import { keyBy } from 'lodash';
import { PAID_PLANS_APP_ID } from '../constants/applications';
import { getApplicationConfig } from '@wix/communities-forum-client-commons';

export const getAllPaidPlans = (state) => state.paidPlans || [];

export const hasPaidPlansForOwner = (state) => getAllPaidPlans(state).length;

export const hasPaidPlans = (state) => {
  if (!getApplicationConfig(state, PAID_PLANS_APP_ID).isInstalled) {
    return false;
  }
  return hasPaidPlansForOwner(state);
};

export const hasCategoryPaidPlansForOwner = (state, groups) => {
  groups = groups || [];
  const plans = getAllPaidPlans(state);
  const plansById = keyBy(plans, 'id');
  for (const groupId of groups) {
    if (plansById[groupId]) {
      return true;
    }
  }
  return false;
};

export const hasCategoryPaidPlans = (state, groups = []) => {
  if (!getApplicationConfig(state, PAID_PLANS_APP_ID).isInstalled) {
    return false;
  }
  return hasCategoryPaidPlansForOwner(state, groups);
};
