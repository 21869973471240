import React from 'react';

export const CurrencySignIcon = (props) => (
  <svg
    data-hook="currency-sign-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill-rule="evenodd"
      d="M9.583 17.5a7.083 7.083 0 1 0 0-14.167 7.083 7.083 0 0 0 0 14.167zm0 .833a7.917 7.917 0 1 1 0-15.833 7.917 7.917 0 0 1 0 15.833zM7.5 11.667h.833c0 .46.373.833.834.833H10a.833.833 0 1 0 0-1.667h-.833a1.667 1.667 0 1 1 0-3.333v-.833H10V7.5c.92 0 1.667.746 1.667 1.667h-.834A.833.833 0 0 0 10 8.333h-.833a.833.833 0 0 0 0 1.667H10a1.667 1.667 0 0 1 0 3.333v.834h-.833v-.834c-.92 0-1.667-.746-1.667-1.666z"
    />
  </svg>
);
