import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import { EmptyStatesTwoSides } from '../empty-states-two-sides';
import withTranslate from '../../../common/components/with-translate/with-translate';
import Button from '../button';
import ensureAuth from '../../hoc/ensure-auth';
import styles from './not-logged-in-no-plans-empty-state.scss';
import withDeviceType from '../../hoc/with-device-type';

const ButtonSecure = ensureAuth(Button);

class NotLoggedInNoPlansEmptyState extends Component {
  makeTranslation(authMode, key) {
    return this.props.t(`private-category-restriction-anonymous-${authMode}-all.${key}`);
  }

  render() {
    const { buttonClicked, isDesktop } = this.props;

    return (
      <EmptyStatesTwoSides
        secondary={{
          title: this.makeTranslation('login', 'title'),
          content: this.makeTranslation('login', 'content'),
          renderCta: () => (
            <ButtonSecure
              className={styles.fullWidth}
              requestLoginMode="login"
              isSecondary
              isSmall={isDesktop}
              beforeRequestLogin={() => buttonClicked('login')}
            >
              {this.makeTranslation('login', 'cta')}
            </ButtonSecure>
          ),
        }}
        primary={{
          title: this.makeTranslation('register', 'title'),
          content: this.makeTranslation('register', 'content'),
          renderCta: () => (
            <ButtonSecure
              className={styles.fullWidth}
              requestLoginMode="signup"
              isSmall={isDesktop}
              beforeRequestLogin={() => buttonClicked('signup')}
            >
              {this.makeTranslation('register', 'cta')}
            </ButtonSecure>
          ),
        }}
        type="log_in_needed"
      />
    );
  }
}

NotLoggedInNoPlansEmptyState.propTypes = {
  t: PropTypes.func,
  buttonClicked: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  buttonClicked: (type) => actions.buttonClicked({ name: 'all-categories-hidden', type }),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withDeviceType,
)(NotLoggedInNoPlansEmptyState);
