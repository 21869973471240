import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Button from '../button';
import styles from './button-group.scss';

const ButtonGroup = ({
  primaryButtonComponent,
  primaryButtonText,
  primaryButtonProps,
  isPrimaryButtonVisible,
  secondaryButtonComponent,
  secondaryButtonText,
  secondaryButtonProps,
  isSecondaryButtonVisible,
  isCentered,
  alwaysLtr,
  alwaysHorizontal,
  theme,
}) => (
  <div className={styles.wrapper}>
    <div
      className={classNames(styles.container, {
        [styles.isCentered]: isCentered,
        [styles.alwaysLtr]: alwaysLtr,
        [styles.alwaysHorizontal]: alwaysHorizontal,
      })}
    >
      {renderButton(secondaryButtonComponent, isSecondaryButtonVisible, secondaryButtonText, {
        ...secondaryButtonProps,
        theme,
        isSecondary: true,
        className: classNames(styles.button, 'button-group__secondary-button'),
        'data-hook': 'cancel-button',
      })}
      {renderButton(primaryButtonComponent, isPrimaryButtonVisible, primaryButtonText, {
        ...primaryButtonProps,
        theme,
        className: classNames(styles.button, 'button-group__primary-button'),
        'data-hook': 'submit-button',
      })}
    </div>
  </div>
);

function renderButton(Component, isVisible, text, props) {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.buttonWrapper}>
      <Component {...props} forceFontSize={true}>
        {text}
      </Component>
    </div>
  );
}

ButtonGroup.propTypes = {
  primaryButtonComponent: PropTypes.any,
  primaryButtonText: PropTypes.string,
  primaryButtonProps: PropTypes.object,
  isPrimaryButtonVisible: PropTypes.bool,
  secondaryButtonComponent: PropTypes.any,
  secondaryButtonText: PropTypes.string,
  secondaryButtonProps: PropTypes.object,
  isSecondaryButtonVisible: PropTypes.bool,
  isCentered: PropTypes.bool,
  alwaysLtr: PropTypes.bool,
  theme: PropTypes.string,
};

ButtonGroup.defaultProps = {
  primaryButtonComponent: Button,
  primaryButtonProps: {},
  isPrimaryButtonVisible: true,
  secondaryButtonComponent: Button,
  secondaryButtonProps: {},
  isSecondaryButtonVisible: true,
  alwaysLtr: false,
  alwaysHorizontal: false,
};

export default ButtonGroup;
