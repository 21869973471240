import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './empty-states.scss';
import { getCategoryFromLocation } from '../../../common/selectors/categories-selectors';
import { connect } from '../../../common/components/runtime-context';

class EmptyStates extends React.Component {
  componentDidMount() {
    const { categoryId, type, userEventsShowEmptyState } = this.props;
    type &&
      userEventsShowEmptyState({
        categoryId,
        type,
      });
  }

  render() {
    const { className, contentFontClassName, titleFontClassName, title, content, children } =
      this.props;
    const containerClassName = classNames(
      styles.container,
      className,
      contentFontClassName,
      'forum-card-background-color',
      'forum-text-color',
    );
    return (
      <div className={containerClassName}>
        <div className={titleFontClassName}>
          <div className={styles.header} data-hook="empty-states__header">
            {title}
          </div>
        </div>
        <div className={styles.content} data-hook="empty-states__content">
          {content}
        </div>
        {children && (
          <div className={styles.controls} data-hook="empty-states__sign-up">
            {children}
          </div>
        )}
      </div>
    );
  }
}

const mapRuntimeToProps = (state, ownProps, actions) => {
  const currentCategory = getCategoryFromLocation(state);
  return {
    userEventsShowEmptyState: actions.userEventsShowEmptyState,
    categoryId: currentCategory._id,
  };
};

EmptyStates.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
};

export default flowRight(withFontClassName, connect(mapRuntimeToProps))(EmptyStates);
