import React from 'react';
import forDirection from '../../hoc/for-direction';

export const ArrowSubcategoriesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" {...props}>
    <path d="M16.293 14H6V4h1v9h9.293l-4.148-4.14c-.195-.195-.195-.511 0-.707.195-.195.512-.195.707 0l5.355 5.347-5.355 5.355c-.195.196-.512.196-.707 0-.195-.195-.195-.512 0-.707L16.293 14z" />
  </svg>
);

export const ArrowSubcategoriesIconRtl = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M16.293 14H6V4h1v9h9.293l-4.148-4.14c-.195-.195-.195-.511 0-.707.195-.195.512-.195.707 0l5.355 5.347-5.355 5.355c-.195.196-.512.196-.707 0-.195-.195-.195-.512 0-.707L16.293 14z"
      transform="translate(24, 0) scale(-1, 1)"
    />
  </svg>
);

export default forDirection(ArrowSubcategoriesIcon, ArrowSubcategoriesIconRtl);
