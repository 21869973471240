import React from 'react';
import PropTypes from 'prop-types';
import withAuth from '../../hoc/with-auth';
import LoggedInNoAccessWithPlansEmptyState from './logged-in-no-access-with-plans-empty-state';
import LoggedInNoAccessEmptyState from './logged-in-no-access-empty-state';
import NotLoggedInWithPlansEmptyState from './not-logged-in-with-plans-empty-state';
import NotLoggedInNoPlansEmptyState from './not-logged-in-no-plans-empty-state';

const NoCategoriesEmptyState = ({ isAuthenticated, hasPaidPlans, ...props }) => {
  if (hasPaidPlans) {
    return isAuthenticated ? (
      <LoggedInNoAccessWithPlansEmptyState {...props} />
    ) : (
      <NotLoggedInWithPlansEmptyState {...props} />
    );
  } else {
    return isAuthenticated ? <LoggedInNoAccessEmptyState /> : <NotLoggedInNoPlansEmptyState />;
  }
};

NoCategoriesEmptyState.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  hasPaidPlans: PropTypes.bool,
};

export default withAuth(NoCategoriesEmptyState);
